import Corpo from "../../components/corpo";
import Layout from "../../layout/template";
import CorpoPremium from "./corpo";

export default function Premium(){
    return(
        <>
        <Layout>
        <CorpoPremium/>
        </Layout>
        </>
    )
}