import Layout from "../../layout/template";
import Logo from "../../imagens/logo/logo.png"
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Safe from '../../imagens/assets/safe.png'





function WebSite() {

    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [sectionPosition1, setSectionPosition1] = useState('top-[100%]');
    const [sectionPosition2, setSectionPosition2] = useState('top-[100%]');
    const [sectionPosition3, setSectionPosition3] = useState('top-[100%]');

    const location = useLocation();



    const windowHeight = window.innerHeight;

    const calculateScrollSection = () => {
        const scrollPosition = window.scrollY;
        console.log(scrollPosition)

        if (scrollPosition == 0) {
            setSectionPosition1('top-[100%]');
            setSectionPosition2('top-[100%]');
            setSectionPosition3('top-[100%]');
            return
        }

        if (scrollPosition < windowHeight) {
            setSectionPosition1('top-0');
            setSectionPosition2('top-[100%]');
            setSectionPosition3('top-[100%]');
        } else if (scrollPosition < 2 * windowHeight) {
            setSectionPosition1('top-0');
            setSectionPosition2('top-0');
            setSectionPosition3('top-[100%]');
        } else {
            setSectionPosition1('top-[-200%]');
            setSectionPosition2('top-0');
            setSectionPosition3('top-0');
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', calculateScrollSection);
        return () => window.removeEventListener('scroll', calculateScrollSection);
    }, []);


    useEffect(() => {
        setTimeout(() => {
            document.getElementById("logoOpacity").classList.remove("opacity-0")

        }, 10);


    }, [location.pathname]);



    return (


        <Layout>

            <div className="w-full h-[3000px]   ">
                <div id="logoOpacity" className="flex flex-col justify-center items-center h-full fixed top-[10%] bottom-0 right-0 left-0 duration-1000 opacity-0  ">
                    <img src={Logo} alt="logo" className="lg:w-4/12 h-auto duration-1000  " />



                    <div className="leading-3">
                        <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-pink-950 to-violet-500 font-semibold">Transformando Suas Ideias em Realidade! </p>
                        <p className="text-[11px] font-semibold">veja alguns dos nossos cases de sucesso abaixo.</p>
                    </div>

                    <div id="arrow" className="  text-purple-700 duration-1000  animate-slide ">

                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-chevron-double-down" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>

                    </div>



                </div>



                <section className={`h-screen bg-green-700  grid lg:grid-cols-2 items-center justify-center  fixed ${sectionPosition1} bottom-0 right-0 left-0 duration-1000 text-white lg:px-24 px-6`}>

                    <div className="w-full lg:col-span-1 col-span-2 -mb-32 lg:-mb-80">
                        <img src={Safe} alt="" />
                    </div>

                    <div className="lg:col-span-1 lg:-mb-80">
                        <h1 className="text-4xl py-4 ">Safe</h1>
                        <p className="">
                        O projeto Safe foi especialmente concebido para atender as necessidades dos profissionais de atividade física que colaboram com o Sistema Único de Saúde (SUS). Em uma era em que a tecnologia desempenha um papel crucial em diversos setores, incluindo a saúde e o bem-estar, o Safe se destaca como uma ferramenta valiosa para professores de educação física e outros especialistas na área de movimento e exercício.
                        </p>
                    </div>

                    <div className="-mt-32 lg:mt-0  text-white duration-1000  animate-slide col-span-2  flex justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-chevron-double-down" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>

                </section>

  {/*
  
                  <section className={`h-screen bg-pink-700  grid lg:grid-cols-2 items-center justify-center  fixed ${sectionPosition2} bottom-0 right-0 left-0 duration-1000 text-white lg:px-24 px-6`}>

                    <div className="w-full lg:col-span-1 col-span-2 -mb-32 lg:-mb-80">
                        <img src={Safe} alt="" />
                    </div>

                    <div className="lg:col-span-1 lg:-mb-80">
                        <h1 className="text-4xl py-4 ">Safe</h1>
                        <p className="">
                            Heineken
                            Sendo a marca mundialmente mais associada à Champions League, a Heineken preparou uma promoção para levar os verdadeiros fãs de futebol para assistir à final em Cardiff.
                            Responsáveis por toda a parte de tecnologia da solução, desenvolvemos o hotsite da campanha contemplando sorteio instantâneo de mais de 6.000 brindes, sorteio de vencedores semanais para participação do quiz, na qual o vencedor iria para a Final da UEFA. Tudo de acordo com as normas da CAIXA. Além disso, conseguimos implementar o site no ambiente da própria Heineken, sendo os primeiros no mundo a conseguir o feito e bater recorde de acessos no mundo.
                        </p>
                    </div>

                    <div className="-mt-32 lg:mt-0  text-white duration-1000  animate-slide col-span-2  flex justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-chevron-double-down" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>

                </section>




                <section className={`h-screen bg-blue-700  grid lg:grid-cols-2 items-center justify-center  fixed ${sectionPosition3} bottom-0 right-0 left-0 duration-1000 text-white lg:px-24 px-6`}>

                    <div className="w-full lg:col-span-1 col-span-2 -mb-32 ">
                        <img src={Safe} alt="" />
                    </div>

                    <div className="lg:col-span-1 ">
                        <h1 className="text-4xl py-4 ">Safe</h1>
                        <p className="">
                            Heineken
                            Sendo a marca mundialmente mais associada à Champions League, a Heineken preparou uma promoção para levar os verdadeiros fãs de futebol para assistir à final em Cardiff.
                            Responsáveis por toda a parte de tecnologia da solução, desenvolvemos o hotsite da campanha contemplando sorteio instantâneo de mais de 6.000 brindes, sorteio de vencedores semanais para participação do quiz, na qual o vencedor iria para a Final da UEFA. Tudo de acordo com as normas da CAIXA. Além disso, conseguimos implementar o site no ambiente da própria Heineken, sendo os primeiros no mundo a conseguir o feito e bater recorde de acessos no mundo.
                        </p>
                    </div>

           

                </section>

  
  
  */}







            </div>


        </Layout>


    );
}

export default WebSite;