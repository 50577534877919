import { Link } from "react-router-dom"
import Logo from "../imagens/logo/logo.png"
export default function Header() {



  function openMenu() {
    const toggleMenuBtn = document.querySelector('#toggleMenu');
    toggleMenuBtn.classList.toggle('rotate-180'); // rotate svg menu icon
    document.querySelector(".sidebar").classList.toggle("ml-[450px]");
    document.querySelector(".sidebar").classList.toggle("opacity-0");
    document.querySelector(".sidebar--opacidade").classList.toggle("hidden");
  }


  return (
    <>
      <div className=" bg-slate-200 w-full flex justify-center items-center drop-shadow-lg ">
        <div className="w-11/12 py-2 lg:w-10/12 flex justify-between ">
          <Link to="/">
            <img src={Logo} className="w-24 " />
          </Link>
          {/* MENU */}
          <div className="lg:flex text-gray-700 font-bold w-6/12 justify-between hidden lg:block ">

            <Link to="/" className="flex items-center justify-center m-1 w-4/12 rounded-md hover:bg-purple-600 hover:text-white duration-200 cursor-pointer">
              <div>
                HOME
              </div>
            </Link>

            <Link to="/" className="flex items-center justify-center m-1 w-4/12 rounded-md hover:bg-purple-600 hover:text-white duration-200 cursor-pointer">
              <div >
                FLYER PSD
              </div>
            </Link>


            <Link to="/website" className="flex items-center justify-center m-1 w-4/12 rounded-md hover:bg-purple-600 hover:text-white duration-200 cursor-pointer">
              <div >
                WEBSITE/APP
              </div>
            </Link>


            <Link to="/contato" className="flex items-center justify-center  m-1 w-4/12 rounded-md hover:bg-purple-600 hover:text-white duration-200 cursor-pointer">
              <div>
                CONTATO
              </div>
            </Link>

          </div>

          <Link to="/premium">
            <div className="bg-green-600 mt-1 cursor-pointer hover:scale-105 font-semibold flex justify-center items-center text-white text-[0.8em] rounded-lg p-2 hidden lg:block">ADQUIRA PREMIUM</div>
          </Link>

          <nav className="block sm:hidden relative">
            <div className="">
              <button onClick={openMenu}>
                <svg id="toggleMenu" className="text-purple-800 h-10 w-10 transform transition-all duration-300" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor"><path d="M0,0h24v24H0V0z" fill="none" /><path d="M3,18h13v-2H3V18z M3,13h10v-2H3V13z M3,6v2h13V6H3z M21,15.59L17.42,12L21,8.41L19.59,7l-5,5l5,5L21,15.59z" /></svg>
              </button>
            </div>

          </nav>



        </div>
      </div>


      <div onClick={openMenu} className="cursor-pointer  ">

        <div className=" sidebar--opacidade fixed top-0 bottom-0 left-[0px] right-0 bg-black  z-50 opacity-75 duration-[2000ms]  hidden "></div>

        <div onClick={openMenu} className="sidebar  fixed top-0 bottom-0 overflow-y-auto lg:hidden block opacity-0 ml-[450px]    p-2 w-[250px] text-center text-[#42aa2f] bg-purple-950 z-[99] duration-1000"  >
          <div className="flex justify-between">
            <div onClick={openMenu} className="items-center ml-4 flex">
              <svg
                id="Camada_1"
                data-name="Camada 1"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 62.58 62.58">
                <path
                  fill="white"
                  d="M23.43,81.31h0a8.9,8.9,0,0,1,0-12.56l47.1-47.1a5,5,0,0,1,7.07,0h0a8.9,8.9,0,0,1,0,12.56L25.5,81.31A5,5,0,0,1,23.43,81.31Z"
                  transform="translate(-19.23 -20.19)" />
                <path
                  fill="white"
                  d="M80.35,78.57h0a8.9,8.9,0,0,1-12.56,0l-47.1-47.1a5,5,0,0,1,0-7.07h0a8.9,8.9,0,0,1,12.56,0l47.1,47.1A5,5,0,0,1,80.35,78.57Z"
                  transform="translate(-19.23 -20.19)" />
              </svg>
            </div>
            <div className="text-white font-medium mr-4 text-2xl">MENU</div>
          </div>
          <hr className="w-full mt-3" />

          {/* HOME */}
          <Link to="/">
            <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-purple-600 scale-90 hover:scale-100 hover:border-l-2 hover:border-white ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="white"
                className="bi bi-house-door"
                viewBox="0 0 16 16"
              >
                <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
              </svg>
              <span className="text-base ml-4 text-white">Home</span>
            </div>
          </Link>


          {/* Flyer PSD */}
          <Link to="/">
            <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-purple-600 scale-90 hover:scale-100 hover:border-l-2 hover:border-white ">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-images" viewBox="0 0 16 16">
                <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
              </svg>

              <span className="text-base ml-4 text-white">Flyer PSD</span>
            </div>
          </Link>



          {/* Website/App */}
          <Link to="/website">
            <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-purple-600 scale-90 hover:scale-100 hover:border-l-2 hover:border-white ">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-menu-button-wide" viewBox="0 0 16 16">
                <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z" />
                <path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
              </svg>
              <span className="text-base ml-4 text-white">Website/App</span>
            </div>
          </Link>



          {/* Contato */}
          <Link to="/contato">
            <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-purple-600 scale-90 hover:scale-100 hover:border-l-2 hover:border-white ">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>

              <span className="text-base ml-4 text-white">Contato</span>
            </div>
          </Link>


          {/* Premium */}
          <div className="animate-pulse p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-purple-600 scale-90 hover:scale-100 hover:border-l-2 hover:border-white ">

            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-upc-scan" viewBox="0 0 16 16">
              <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
            </svg>
            <span className="text-base ml-4 text-green-500 font-bold ">Adquira Premium</span>
          </div>


        </div>
      </div>

    </>
  )
}