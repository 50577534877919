import { BrowserRouter, Routes, Route } from "react-router-dom";
import Premium from "./pages/premium/premium";
import Home from "./pages/home";
import Contato from "./pages/Contato/contato";
import WebSite from "./pages/website/website";


export default function RoutesApp() {


    return(
      
     <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/home" element={<Home />} />
       <Route exact path="/premium" element={<Premium />} />
       <Route exact path="/contato" element={<Contato />} />
       <Route exact path="/website" element={<WebSite />} />
       </Routes>

       </BrowserRouter>
     
    )
}