import Corpo from "../../components/corpo";
import Layout from "../../layout/template";

export default function Home(){
    return(
        <>
        <Layout>
            <Corpo/>
        </Layout>
        </>
    )
}