import Check from "../../imagens/icons/check.png"

export default function TitleCheck(props){
    return(
        <>
        <div className="flex w-10/12 items-center">
                <img className="w-[18px] h-[18px] ml-3" src={Check}/>
                <div className={`ml-2 font-bold text-${props.textcolor}`}>{props.texto}</div>               
               </div>
        </>
        )
}