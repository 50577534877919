import CardPremium from "./card";


export default function Mensal(){
    return(
        <>
        <div className="flex mb-10 mt-10">
        <CardPremium downloads="2" price="69,90" titulo="Premium Classic" color="purple" largura="380px" altura="5"/>
         <div className="z-1 scale-110">
        <CardPremium downloads="6" price="129,90" titulo="Premium Plus" color="white" largura="400px" altura="10"/>
        </div>
        <CardPremium downloads="3" price="89,90" titulo="Premium Beta" color="gray" largura="380px" altura="5"/>
        </div>
     
        
        </>
    )
}