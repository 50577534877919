import Mensal from "./mensal";


export default function CorpoPremium() {
    return (
        <>
            <div className="w-full bg-gray-100 min-h-[100vh] flex items-center flex-col">
                <div className="text-purple-600 font-bold  text-4xl pt-10 pb-5">Junte-se ao nosso premium</div>
                <div className="text-lg w-7/12 font-bold">Conte com uma ampla variedade de recursos gráficos de alta qualidade. Você pode navegar pelo nosso acervo e baixar o que precisar para usar em seus projetos criativos.</div>
                <div className="bg-gray-200 w-full mt-10 flex flex-col items-center  mb-10">
                    <div className="w-2/12 flex mt-10">
                        <div className="bg-gray-300 rounded-md justify-between flex p-2 w-full">
                            <div className="bg-white rounded-md p-2 w-[100px] font-bold cursor-pointer hover:bg-green-400"> Mensal</div>
                            <div className="rounded-md p-2 w-[100px] font-bold cursor-pointer hover:bg-green-400"> Anual</div>

                        </div>
                    </div>



                    <div className="mt-5 mb-5">
                        <Mensal />
                    </div>




                </div>
                <div className="w-full flex items-center flex-col ">
                    <div className="text-4xl font-bold">Benefícios só para Premium.</div>
                    <div className="mb-10 mt-2 text-md text-gray-700 w-8/12 font-semibold">Com os recursos que oferecemos, você tem a oportunidade de criar trabalhos mais atraentes e valiosos, o que pode resultar em pagamentos mais altos por seu trabalho. No entanto, se você preferir, pode cancelar seu acesso aos nossos recursos a qualquer momento, sem incorrer em quaisquer custos adicionais</div>

                    <div className="mt-10 mb-10 w-9/12 flex flex-wrap gap-y-10">

                        <div className="w-4/12 p-2">
                            <div class="flex-none group inline-flex items-center justify-center w-12 h-12 ml-3 mb-8 relative">
                                <div class="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-purple-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
                                <div class="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-purple-700 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>


                                <svg class="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
                                </svg>


                            </div>
                            <div>
                                <div className="w-full">
                                    <div className="text-xl font-bold">Não há Limites</div>
                                    <div className="text-sm  text-gray-500">Baixe tudo que você precisar e sem tempo de espera, com o PREMIUM PLUS você pode fazer até 6 downloads diários.</div>
                                </div>


                            </div>
                        </div>

                        <div className="w-4/12 p-2">
                            <div class="flex-none group inline-flex items-center justify-center w-12 h-12 ml-3 mb-8 relative">
                                <div class="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-purple-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
                                <div class="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-purple-700 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>


                                <svg class="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
</svg>


                            </div>
                            <div>
                                <div className="w-full">
                                    <div className="text-xl font-bold">Exclusividade</div>
                                    <div className="text-sm  text-gray-500">Tenha acesso exclusivo às artes PREMIUM criadas por nossos profissionais de design gráfico.</div>
                                </div>


                            </div>
                        </div>

                        <div className="w-4/12  p-2">
                            <div class="flex-none group inline-flex items-center justify-center w-12 h-12 ml-3 mb-8 relative">
                                <div class="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-purple-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
                                <div class="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-purple-700 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>


                                <svg class="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z" clip-rule="evenodd"></path>
</svg>


                            </div>
                            <div>
                                <div className="w-full">
                                    <div className="text-xl font-bold">Sem anúncios</div>
                                    <div className="text-sm  text-gray-500">Navegue em todo o site sem ver anúncios. Com PREMIUM, nenhuma propaganda aparece.</div>
                                </div>


                            </div>
                        </div>


                        <div className="w-4/12  p-2">
                            <div class="flex-none group inline-flex items-center justify-center w-12 h-12 ml-3 mb-8 relative">
                                <div class="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-purple-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
                                <div class="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-purple-700 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>


                                <svg class="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clip-rule="evenodd"></path>
</svg>


                            </div>
                            <div>
                                <div className="w-full">
                                    <div className="text-xl font-bold">Não usa espaço no seu HD</div>
                                    <div className="text-sm  text-gray-500">Tudo na NUVEM, visualize todos os arquivos antes de fazer o download para o seu computador, baixe apenas o que precisar.</div>
                                </div>


                            </div>
                        </div>


                        <div className="w-4/12  p-2">
                            <div class="flex-none group inline-flex items-center justify-center w-12 h-12 ml-3 mb-8 relative">
                                <div class="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-purple-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
                                <div class="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-purple-700 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>


                                <svg class="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
<path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
</svg>


                            </div>
                            <div>
                                <div className="w-full">
                                    <div className="text-xl font-bold">Artes Atualizadas</div>
                                    <div className="text-sm  text-gray-500">Nossa equipe de designer está atualizando as artes todo tempo, receba atualizações sem nenhum custo adicional.</div>
                                </div>


                            </div>
                        </div>


                        <div className="w-4/12 p-2">
                            <div class="flex-none group inline-flex items-center justify-center w-12 h-12 ml-3 mb-8 relative">
                                <div class="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-purple-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
                                <div class="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-purple-700 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>


                                <svg class="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path>
</svg>


                            </div>
                            <div>
                                <div className="w-full">
                                    <div className="text-xl font-bold">Suporte</div>
                                    <div className="text-sm  text-gray-500">Estamos com você em nossos principais canais de comunicação, para atender suas dúvidas, sugestões e agradecimentos.</div>
                                </div>


                            </div>
                        </div>


                       


                    </div>
                </div>
            </div>
        </>
    )
}