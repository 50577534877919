import { useEffect, useState } from "react"
import TitleCheck from "./titlecheck"




export default function CardPremium(props){

    const [color, setColor] = useState(props.color)

    useEffect(()=>{
        console.log(color, "??")
    },[])
return(
    <>
      <div className={`rounded-lg border-gray-300 bg-gray-100 border-2 w-[${props.largura}] flex flex-col items-center `}>
          <div className="mt-5 bg-purple-300 text-purple-500 font-bold rounded-lg p-1 w-6/12 uppercase">{props.titulo}</div>
          <div className="flex mt-5">
            <div className="text-4xl font-bold mr-2">R$</div>
            <div className="text-4xl font-bold mr-2">{props.price}</div>
            <div className="text-sm font-bold flex items-end">/MÊS</div>
          </div>
            <div className={`mt-2 mb-5 font-bold text-sm text-gray-400 `}>Até {props.downloads} Dowloads por dia</div>
            <div className={color == "white" ? `bg-purple-600 py-${props.altura} px-2 flex justify-center w-full` : `bg-gray-200 h-full py-${props.altura} px-2 flex justify-center w-full` } >
              <div className="w-full flex flex-col items-center gap-y-4 justify-center">
              
              <TitleCheck texto={color == "white" ? "Qualquer arquivo do site" : "Qualquer arquivo PSD"} textcolor={color == "white" ? "white" : "black"} />
              <TitleCheck texto={color == "white" ? "Downloads simultâneos" : "1 Download por Vez"} textcolor={color == "white" ? "white" : "black"} />
              <TitleCheck texto="Velocidade máxima" textcolor={color == "white" ? "white" : "black"}  />
              <TitleCheck texto="Site sem anúncios" textcolor={color == "white" ? "white" : "black"} />
              <TitleCheck texto="Atualizações Recorrentes" textcolor={color == "white" ? "white" : "black"} />


              <div className="mt-5 p-2 px-4 rounded-md  font-bold hover:bg-green-600 hover:text-white duration-500 bg-slate-50 border-purple-700 border-2 text-gray-600 cursor-pointer">Assine Agora</div>

               </div>

              
            </div>

        </div>
    </>
)
}