
import { useRef, useState } from "react"
import Egito from "../imagens/video/egito.mp4"
import imagemMoon from "../imagens/flyers/moon.png"
import AgendadaSemana from "../imagens/video/agendadasemana.mp4"
import Marks from "../imagens/video/marks.mp4"
import playbutton from "../imagens/icons/play.png"
import Aniver from "../imagens/flyers/aniver.png"
import Farao from "../imagens/flyers/farao.png"
import CityFuture from "../imagens/flyers/city-future.png"
import Baile1 from "../imagens/flyers/baile1.png"
import Quartinha from "../imagens/flyers/quartinha.png"
import SoVem from "../imagens/flyers/sertanejo-sovem.png"
import MoonImg from "../imagens/flyers/moon.png"
import Nature from "../imagens/flyers/nature.png"
import Payment from "./payment"
import Moon from "../imagens/video/moon.mp4"
import Agenda from "../imagens/flyers/agenda.png"
import Marks2 from "../imagens/flyers/marks.png"

export default function Corpo() {

    const [videos, setVideos] = useState([
        { id: 1, valor: 400, nome: "Video + Imagem : Castle Moon", video: Moon, imagem: imagemMoon },
        { id: 2, valor: 600, nome: "Video + Imagem : Egito", video: Egito, imagem: Farao },
        { id: 3, valor: 450, nome: "Video + Imagem : Agenda Semanal", video: AgendadaSemana, imagem: Agenda },
        { id: 4, valor: 250, nome: "Video + Imagem : Show Especial Funk", video: Marks, imagem: Marks2 },


    ])

    const [flyer, setFlyer] = useState([
        { id: 1, valor: 40, nome: "Flyer Aniversário, para festa", imagem: Aniver },
        { id: 2, valor: 200, nome: "Flyer Egito", imagem: Farao },
        { id: 3, valor: 100, nome: "Flyer City Future", imagem: CityFuture },
        { id: 4, valor: 20, nome: "PROMO* Flyer de Funk", imagem: Baile1 },
        { id: 5, valor: 50, nome: "Flyer Sertanejo, Quartinha", imagem: Quartinha },
        { id: 6, valor: 60, nome: "Flyer Sertanejo, Só Vem", imagem: SoVem },
        { id: 7, valor: 120, nome: "Flyer Castle Moon", imagem: MoonImg },
        { id: 8, valor: 40, nome: "Flyer Nature", imagem: Nature },

    ]);

    const [product, setProduct] = useState("teste");

    function cartX(item) {
        openPayment()
        setProduct(item)
        console.log("o que é", item)

    }

    async function sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    };
 
    const videosRefs = useRef([]);

    function play(id) {
        videosRefs.current.forEach((videoRef) => {
            if (videoRef.id === `video${id}`) {
                if (videoRef.paused) {
                    // Se o vídeo estiver pausado, reproduz
                    videoRef.play();
                    videoRef.muted = false;
                } else {
                    // Se o vídeo estiver em execução, pause
                    videoRef.pause();
                   
                }
            } else {
                videoRef.pause();
                videoRef.currentTime = 0;
                const fundoElements = document.querySelectorAll(".fundoplay");
                fundoElements.forEach((element) => {
                    element.classList.remove("hidden");
                });
            }
        });
    
        setTimeout(() => {
            videosRefs.current.forEach((videoRef) => {
                videoRef.pause();
            });
        }, 15000);
    
        document.getElementById(`fundo${id}`).classList.add("hidden");
    }


    function openPayment() {
        document.getElementById("payment").classList.toggle("hidden");
    
        setTimeout(() => {
            document.getElementById("payment--card").classList.toggle("mb-[-700px]")
        }, 100)

    }

    return (
        <>
            <div className="w-full bg-purple-950">
                <div className="text-white font-bold text-3xl lg:text-6xl py-10">Elementos de PSD totalmente editáveis</div>


                {/* FLYERS */}
                <div className="w-full justify-center flex">
                    <div className="w-full px-2 md:px-0 md:w-8/12 flex flex-wrap shadow-lg">


                        {flyer.map((item, index) =>
                            <div className="md:w-4/12 lg:w-3/12 w-6/12 justify-center group  duration-300 flex relative">
                                <div className="absolute mt-2 mx-1 w-full h-full group-hover:opacity-0 group-hover:hidden duration-300 bg-purple-900 z-1 opacity-40"></div>
                                <div className="w-full my-2 mx-1 rounded-lg bg-white">

                                    <div className="rounded-t-lg overflow-hidden h-[350px]">
                                        <img src={item.imagem} />
                                    </div>
                                    <div className="w-full flex flex-col">
                                        <div className="py-4">
                                            <div className="text-gray-500 font-bold uppercase text-[0.5em] lg:text-[0.6em]">PSD EDITÁVEL</div>
                                            <div className="w-full text-md lg:text-lg px-1 font-bold min-h-[60px] uppercase text-purple-700">{item.nome}</div>
                                        </div>
                                    </div>

                                    <div className="w-full flex">
                                        <div className="w-6/12 font-bold flex p-2 justify-center items-center">
                                            <div className="w-full justify-between border-2 flex items-center border-purple-300 p-2 rounded-lg ">
                                                <div className="left-2 text-[0.5em] text-purple-300">R$</div>
                                                <div className="text-green-500 lg:text-md text-[0.8em]">{item.valor}</div>
                                            </div>
                                        </div>
                                        <div className="w-6/12 p-2 cursor-pointer">
                                            <div onClick={() => { cartX(item) }} className="bg-green-500 text-[0.6em] lg:text-base rounded-lg p-3 font-bold hover:scale-105 ">Comprar</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>






                {/* VIDEOS */}
                <div className="w-full justify-center flex flex-col items-center">
                    <div className="w-11/12 lg:w-9/12 flex flex-col items-start mt-5">
                        <div className="text-white text-4xl font-bold">VÍDEO + FLYER PSD</div>
                        <div className="text-[#fc0680] text-lg ">Melhores combos de vídeo e imagem editável da internet</div>
                    </div>
                    <div className="w-full flex flex-wrap shadow-lg px-2 md:px-0 md:w-8/12">


                        {videos.map((item, index) =>
                            <div key={index} className="md:w-4/12 lg:w-3/12 w-6/12 justify-center group  duration-300 flex relative">

                                <div className="w-full my-2 mx-1 rounded-lg bg-white">

                                    <div onClick={() => { play(item.id) }} className="rounded-t-lg overflow-hidden cursor-pointer h-[350px]">

                                        <div id={`fundo` + item.id} className="fundoplay cursor-pointer absolute w-full h-[65%]  duration-300 bg-[#6e1ee02e] z-1">
                                            <div className="cursor-pointer flex justify-center items-center  relative top-[35%] lg:top-[50px]">
                                                <img className="group-hover:scale-105 duration-150 cursor-pointer w-24  lg:w-48" src={playbutton} />
                                            </div>
                                        </div>


                                        <video ref={(ref) => (videosRefs.current[item.id] = ref)} className="w-full videoon" id={`video` + item.id} src={item.video} poster={item.imagem} playsInline={true} muted={true} loop={true} />
                                    </div>
                                    <div className="w-full flex flex-col">
                                        <div className="py-4">
                                        <div className="text-gray-500 font-bold uppercase text-[0.5em] lg:text-[0.6em]">PSD EDITÁVEL</div>
                                            <div className="w-full text-md lg:text-lg px-1 font-bold min-h-[60px] uppercase text-purple-700">{item.nome}</div>
                                        </div>
                                    </div>

                                    <div className="w-full flex">
                                        <div className="w-6/12 font-bold flex p-2 justify-center items-center">
                                            <div className="w-full justify-between border-2 flex items-center border-purple-300 p-2 rounded-lg ">
                                                <div className="left-2 text-[0.5em] text-purple-300">R$</div>
                                                <div className="text-green-500 lg:text-md text-[0.8em]">{item.valor}</div>
                                            </div>
                                        </div>
                                        <div className="w-6/12 p-2 cursor-pointer">
                                            <div onClick={() => { cartX(item) }} className="bg-green-500 rounded-lg  text-[0.6em] lg:text-base  p-3 font-bold hover:scale-105 ">Comprar</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <Payment product={product} />

        </>
    )
}

