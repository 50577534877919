import Layout from "../../layout/template";
import { useState, useEffect } from "react";
import User from '../../imagens/assets/user.png'
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';


function Contato() {

    

    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [validando, setValidando] = useState(false);

    console.log()

    const handleSubmit = (event) => {
        event.preventDefault();
        // Aqui você pode lidar com os dados do formulário (name, email, message)
        // por exemplo, enviando-os para um servidor ou exibindo-os de alguma forma.
    };

    const background = {
        backgroundImage: `url(${User})`,
        backgroundSize: 'contain',
        backgroundPosition: '80%',
        backgroundAttachment: 'local',
        backgroundRepeat: 'no-repeat',
    };


    function handleClick() {
        setValidando(true)

        if (message == '' || email == '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Preencha todos os campos para enviar a menssagem!',
            })
            setValidando(false)

            return
        }

        var formdata = new FormData();

        formdata.append("email", email);
        formdata.append("celular", "484848484848");
        formdata.append("mensagem", message);
        formdata.append("token", "platiumPay");

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(`https://new-rockeetz-backend.seu.dev.br/api-v1/centraldojogador/suporte`, requestOptions)
            .then((resposta) => resposta.json())
            .then((json) => {
                Swal.fire(
                    "",
                    json.msg,
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire(
                    "",
                    "Erro ao enviar e-mail, tente novamente mais tarde",
                    'error'
                )
                console.log("error", error);
            }).finally(() => {
                setValidando(false)
            })

    }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("tituloContato").classList.remove("-mr-[4000px]")
            document.getElementById("formularioContato").classList.remove("mr-[4000px]", "opacity-0")

        }, 10);
        

    }, [location.pathname]);

    return (

        <Layout>
            <div className="overflow-x-hidden bg-purple-950  ">

                <div id="tituloContato" className="flex items-center justify-center -mr-[4000px] duration-1000 mt-10">
                    <div className="text-white font-bold lg:text-6xl text-[26px] lg:py-10 text-left flex flex-col lg:leading-7 leading-4 ">

                        <p className="whitespace-nowrap">Nossa equipe está <span className="text-green-500">Pronta</span> </p> <br></br>
                        <p className="whitespace-nowrap"> para <span className="text-green-500">Transformar </span></p><br></br>
                        <p className="whitespace-nowrap">seu projeto em  <span className="text-green-500">Sucesso!</span></p><br></br>

                    </div>
                </div>


                <div style={background} className="lg:min-h-screen min-h-[80vh]     flex items-center justify-end  ">

                    <div id="formularioContato" className=" w-full  mr-[4000px] duration-1000 opacity-0 px-4  ">


                        <div  className="w-full max-w-md my-4    p-10 lg:ml-[10%] bg-white rounded-lg shadow-md  ">

                            <h2 className="text-3xl font-bold mb-10 text-center text-purple-600 whitespace-nowrap">Entre em Contato</h2>

                            <form className="text-left" onSubmit={handleSubmit}>
                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm font-bold mb-2 " htmlFor="name">
                                        Nome
                                    </label>
                                    <input
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="name" type="text" placeholder="Seu nome" />
                                </div>

                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="email" type="email" placeholder="Seu email" />
                                </div>

                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                                        Mensagem
                                    </label>
                                    <textarea
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        className=" shadow appearance-none border-2 border-purple-600 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24"
                                        id="message" placeholder="Sua mensagem" />
                                </div>

                                <div className="flex items-center justify-between">
                                    <button disabled={validando} onClick={handleClick} className="disabled:opacity-[70%] duration-500 bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full" type="submit">
                                        {validando ? 'Enviando..' : 'Enviar'}

                                    </button>
                                </div>
                            </form>
                        </div>


                    </div>

                </div>

            </div>



        </Layout>


    );
}

export default Contato;