import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Corpo from './components/corpo';
import RoutesApp from "./routes"

function App() {
  return (
    <div className="App montserrat">
      
      <RoutesApp>
        <Header />
        <Corpo />
      </RoutesApp>

    </div>
  );
}

export default App;
