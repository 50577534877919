import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import Imagem from "../imagens/flyers/farao.png"
import HeaderPix from "../imagens/icons/pix100.png"
import Xfecha from "../imagens/icons/x.png"
import Logoplatium from "../imagens/icons/LogoPlatiumPay.png"
import CompraSegura from "../imagens/icons/compra-segura.png"

export default function Payment(props) {
    const [quantia, setQuantia] = useState(props.valor);
    const [email, setEmail] = useState();
    const [Cpf, setCpf] = useState();
    const [nome, setNome] = useState();
    const [Cep, setCep] = useState();
    const [ddd, setDdd] = useState();
    const [tel, setTel] = useState();
    const [endereco, setEndereco] = useState();
    const [num, setNum] = useState();
    const [bairro, setBairro] = useState();
    const [cidade, setCidade] = useState();
    const [estado, setEstado] = useState();
    const [qrCode, setQrCode] = useState();
    const [prefixoUrl] = useState("https://new-rockeetz-backend.seu.dev.br/api-v1");
    const [aproved, setAproved] = useState(true);


    useEffect(() => {


        let dados = localStorage.getItem("paymentInfo");
        dados = JSON.parse(dados);
        if (dados) {
            setQuantia(dados.valor)
            setCpf(dados.cpf)
            setNome(dados.name)
            setCep(dados.cep)
            setEndereco(dados.rua)
            setBairro(dados.bairro)
            setCidade(dados.cidade)
            setNum(dados.numero)
            setEmail(dados.email)
            setEstado(dados.uf)
            setDdd(dados.telefone.substring(0, 2))
            setTel(dados.telefone.substring(2))
        }
    }, []);




    function openPayment() {
        document.getElementById("payment").classList.toggle("hidden");
        setTimeout(() => {
            document.getElementById("payment--card").classList.toggle("mb-[-700px]")
        }, 100)

    }

    function cancelPayment() {
        openPayment()
        setQrCode("")
        setAproved("false");

        const DadosUsuario = document.getElementById("dados")
        const qrcodeUsuario = document.getElementById("qrcode")
        
        if(aproved){
        DadosUsuario.classList.remove("hidden");
        qrcodeUsuario.classList.add("hidden");  
        }else{
            DadosUsuario.classList.add("hidden");
            qrcodeUsuario.classList.remove("hidden");
        }
       
    }

    function limpa_formulário_cep() {
        //Limpa valores do formulário de cep.
        document.getElementById('rua').value = ("");
        document.getElementById('bairro').value = ("");
        document.getElementById('cidade').value = ("");
        document.getElementById('uf').value = ("");

    }

 

    // função que copia o qrCode
    function cpQrCode() {
        let qrCodeReplace = qrCode.replace(' ""')
        navigator.clipboard.writeText(qrCodeReplace);
        Swal.fire(
            'Sucesso',
            'QR Code copiado!',
            'success'
        );
    }


    function pesquisacep() {

        //Nova variável "cep" somente com dígitos.
        var cep = Cep.replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep)) {

                //Preenche os campos com "..." enquanto consulta webservice.
                document.getElementById('rua').value = "...";
                document.getElementById('bairro').value = "...";
                document.getElementById('cidade').value = "...";
                document.getElementById('uf').value = "...";


                //Cria um elemento javascript.


                //Sincroniza com o callback.
                var Teste = fetch('https://viacep.com.br/ws/' + cep + '/json')
                    .then((response) => response.json())
                    .then((json) => {
                        meu_callback(json);
                    })






            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();

                Swal.fire(
                    'Ops..',
                    'O Cep Digitado é invalido.',
                    'error'
                );
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    };

    function meu_callback(conteudo) {
        if (!("erro" in conteudo)) {
            //Atualiza os campos com os valores.
            setEndereco(conteudo.logradouro)
            setBairro(conteudo.bairro)
            setCidade(conteudo.localidade)
            setEstado(conteudo.uf)
            //document.getElementById('rua').value = (conteudo.logradouro);
            //document.getElementById('bairro').value = (conteudo.bairro);
            //document.getElementById('cidade').value = (conteudo.localidade);
            // document.getElementById('uf').value = (conteudo.uf);

        } //end if.
        else {
            //CEP não Encontrado.
            limpa_formulário_cep();

            Swal.fire(
                'Ops..',
                'CEP não encontrado.',
                'error'
            );
        }
    };


    function validate() {
        if (!Cpf || !nome || !ddd || !tel || !endereco || !num || !bairro || !cidade || !estado || !Cep) {
            Swal.fire(
                'Ops..',
                'Preencha todos os campos!',
                'error'
            );


        }
        else {
            apiQrCode()
            document.getElementById("dados").classList.add("hidden");
            document.getElementById("qrcode").classList.remove("hidden");
          
        }

    }

    // ENVIA A API, QUANDO CLICA EM CONTINAUR

    function apiQrCode() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify({
            "userid": 11,
            "valor": props.product.valor,
            "cpf": Cpf,
            "name": nome,
            "email": email,
            "uf": estado,
            "cidade": cidade,
            "cep": Cep,
            "numero": num,
            "rua": endereco,
            "bairro": bairro,
            "telefone": ddd + tel
        });



        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://pagamento.seu.dev.br/qrcode/4/seudev", requestOptions)
            .then(response => response.json())
            .then(result => {
                let dados = JSON.parse(result);
                setQrCode(dados.qr_code);
                localStorage.setItem("paymentInfo", raw); // Move esta linha aqui
            })
            .catch(error => console.log('error', error));


    }



  // FAZ A VALIDAÇÃO DO CPF
    function valida_cpf(cpf) {
        var cpf = Cpf.replace(/\D/g, '');
        if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        var result = true;
        [9, 10].forEach(function (j) {
            var soma = 0, r;
            cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
                soma += parseInt(e) * ((j + 2) - (i + 1));
            });
            r = soma % 11;
            r = (r < 2) ? 0 : 11 - r;
            if (r != cpf.substring(j, j + 1)) result = false;

            if (!result) {
                Swal.fire(
                    'Ops..',
                    'SEU CPF É INVALIDO.',
                    'error'
                );
            }
        });



        return result;
    }

 
    return (
        <>
            <div id="payment" className="hidden z-[2] bottom-0 fixed h-[100vh] w-full backdrop-blur-md ">
                <div id="payment--card" className="mb-[-700px] duration-300 bg-white w-full absolute bottom-0 lg:h-[95vh] h-[92vh] rounded-t-md">
                    <div className="flex items-center top-0 h-[60px] justify-between rounded-t-md w-full bg-purple-600">
                        <div className="text-xl font-bold text-white pl-3">Pagamento PIX</div>
                        <div onClick={cancelPayment} className="cursor-pointer text-xl font-bold text-white pr-3">X</div>
                    </div>
                    <div className="overflow-hidden w-full">
                        <div className="overflow-y-auto max-h-[650px]">

                            <div id="dados" className="pt-2 lg:pt-20 flex lg:flex-row flex-col w-full justify-center">
                                {/* DADOS PESSOAS */}
                                <div className="flex justify-center w-full lg:w-6/12">
                                    <div className="w-full lg:w-9/12 ">
                                        <div className="w-full flex-wrap flex text-center justify-center">

                                            {/* INPUT NAME*/}
                                            <div className="w-full bg-cinzaprimary py-2 mb-2 flex justify-center">
                                                <div className="flex w-11/12 justify-between">
                                                    <div className="text-gray-800 pl-3 py-1 w-3/12 flex justify-start">
                                                        Nome:
                                                    </div>
                                                    <div className="w-9/12">
                                                        <input type="text" id="nameDeposito" value={nome} onChange={(e) => setNome(e.target.value)} placeholder="Digite o Nome Completo" className="text-sm text-gray-800 text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* INPUT CPF*/}
                                            <div className="w-full bg-cinzaprimary py-2 flex mb-2 justify-center">
                                                <div className="flex w-11/12 justify-between">
                                                    <div className="text-gray-800 pl-3 py-1 w-3/12 flex justify-start">
                                                        CPF:
                                                    </div>
                                                    <div className="w-9/12">
                                                        <input onBlur={valida_cpf} value={Cpf} onChange={e => (setCpf(e.target.value))} id="cpfDeposito" type="number" placeholder="Digite o CPF Corretamente" className="text-sm text-gray-800 text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* INPUT CPF*/}
                                            <div className="w-full bg-cinzaprimary py-2 flex mb-2 justify-center">
                                                <div className="flex w-11/12 justify-between">
                                                    <div className="text-gray-800 pl-3 py-1 w-3/12 flex justify-start">
                                                        E-MAIL:
                                                    </div>
                                                    <div className="w-9/12">
                                                        <input value={email} onChange={e => (setEmail(e.target.value))} id="email" type="email" placeholder="Digite seu Email" className="text-sm text-gray-800 text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                                                    </div>

                                                </div>
                                            </div>



                                            <div className="w-full bg-cinzaprimary py-2 mb-2 flex justify-center">
                                                <div className="flex w-10/12 text-[0.6em] h-[1px] bg-purple-300 justify-center text-purple-400">


                                                </div>
                                            </div>


                                            {/* INPUT CEP E TELEFONE*/}
                                            <div className="w-full bg-cinzaprimary py-2  flex justify-center">
                                                <div className="flex w-10/12 justify-between">
                                                    <div className="w-5/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">CEP</div>
                                                        <input onBlur={pesquisacep} value={Cep} onChange={e => (setCep(e.target.value))} name="cep" type="text" id="cep" size={9} maxLength={9} placeholder="Digite o CEP" className="text-sm text-gray-800 text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>

                                                    </div>
                                                    <div className="w-6/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">Telefone</div>
                                                        <div className="flex justify-between w-full">
                                                            <input id="codigoTelefonePagamento" type="text" placeholder="DDD" value={ddd} onChange={(e) => setDdd(e.target.value)} maxLength={2} className="text-sm text-gray-800 text-center text-sm font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-4/12 mr-2"></input>
                                                            <input id="telefonePagamento" placeholder="Telefone" value={tel} onChange={(e) => setTel(e.target.value)} maxLength={9} className="text-sm text-gray-800 text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-8/12"></input>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            {/* endereço e numero*/}
                                            <div className="w-full bg-cinzaprimary py-2  flex justify-center">
                                                <div className="flex w-10/12 justify-between">
                                                    <div className="w-8/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">Endereço</div>
                                                        <input name="rua" type="text" id="rua" size={60} value={endereco} onChange={(e) => setEndereco(e.target.value)} placeholder="Digite o endereço" className="text-gray-800  text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                                                    </div>
                                                    <div className="w-3/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">Numero</div>

                                                        <input name="numero" id="numero" type="text" placeholder="Nº" value={num} onChange={(e) => setNum(e.target.value)} maxLength={5} className="text-gray-800 text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12 "></input>



                                                    </div>

                                                </div>
                                            </div>

                                            {/* bairro cidade e uf*/}
                                            <div className="w-full bg-cinzaprimary py-2 flex justify-center">
                                                <div className="flex w-10/12 justify-between">
                                                    <div className="w-4/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">Bairro</div>
                                                        <input name="bairro" id="bairro" size={60} type="text" placeholder="Digite o bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} className="text-gray-800 text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                                                    </div>
                                                    <div className="w-4/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">Cidade</div>
                                                        <input name="cidade" type="text" id="cidade" size={40} placeholder="Digite a cidade" value={cidade} onChange={(e) => setCidade(e.target.value)} className="text-gray-800 text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                                                    </div>
                                                    <div className="w-3/12">
                                                        <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start font-bold text-purple-600">Estado</div>

                                                        <input name="uf" type="text" id="uf" maxLength={2} value={estado} onChange={(e) => setEstado(e.target.value)} className="text-sm text-gray-800 text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>



                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>




                                </div>

                                {/* DADOS DO PRODUTO */}
                                <div className="w-full lg:w-6/12 flex flex-col items-center justify-center">
                                    <div className="w-10/12 rounded-lg bg-gray-100">
                                        <div className="flex">
                                            <div className="w-4/12">
                                                <div className="rounded-l-lg flex justify-center items-center overflow-hidden">
                                                    <img className="" src={props.product.imagem} />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-8/12 relative flex-col flex items-center">

                                                <div className="mt-5">
                                                    PSD EDITÁVEL
                                                </div>
                                                <div className="uppercase text-xl font-bold text-purple-300">
                                                    {props.product.nome}
                                                </div>
                                                <div className="mt-2 lg:mt-10 text-4xl lg:text-5xl font-bold text-gray-800">
                                                    R$ {props.product.valor}
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className="w-11/12 mt-2 p-3 flex justify-between lg:w-8/12">
                                        <div onClick={validate} className="cursor-pointer p-2 rounded-md duration-200 hover:text-white hover:bg-purple-700 font-bold bg-green-400">CONTINUAR</div>
                                        <div onClick={cancelPayment} className="font-bold duration-200 text-white hover:text-gray-800 cursor-pointer p-2 rounded-md  bg-gray-400">CANCELAR</div>
                                    </div>
                                </div>
                            </div>


                            <div id="qrcode" className="hidden bg-gray-200 w-full  h-[95vh] backdrop-blur-md justity-center flex ">
                                <div className="w-full flex flex-col items-center">



                                    <div className="flex lg:flex-row flex-col items-center  mt-5 w-full lg:w-9/12">
                                        <div className="bg-white flex-col rounded-lg md:w-8/12 w-11/12 flex justify-center items-center">
                                            {!qrCode ? (
                                                <svg role="status" className="inline mr-2 md:w-4/12 md:h-[25vh] h-6 text-green-200 animate-spin dark:text-[#717171]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#303030" />
                                                </svg>
                                            ) : (
                                                <img className="p-0" src={`https://chart.googleapis.com/chart?chs=280x280&cht=qr&chl=${qrCode}`} alt="" />
                                            )}


                                            <div className="font-bold text-sm mt-2 px-6">


                                                <p>
                                                    1 - Leia o código QR acima no aplicativo Pix
                                                </p>

                                                <p>
                                                    2 - Conclua o depósito com seu banco
                                                </p>

                                                <p>
                                                    3 - Seu produto será enviado automaticamente no seu email em até 24horas.
                                                </p>
                                            </div>

                                            <input className="border-2 border-gray-500 w-11/12 mt-2 px-2" value={qrCode}></input>

                                            <div className="flex  mt-3 mb-4 justify-between w-7/12">
                                                <div onClick={cpQrCode} className="cursor-pointer p-2 rounded-md duration-200 hover:text-white hover:bg-purple-700 font-bold bg-green-400">COPIAR</div>
                                                <div onClick={cancelPayment} className="font-bold duration-200 text-white hover:text-gray-800 cursor-pointer p-2 rounded-md  bg-gray-400">CANCELAR</div>
                                            </div>

                                        </div>
                                        <div className="md:w-4/12 w-full bg-gray-200">
                                            <div className="w-full relative p-4 flex-col flex items-start">


                                                <div className="mb-3 uppercase rounded-md bg-gray-100 p-2 w-full text-1xl font-bold text-purple-600">
                                                    {props.product.nome}
                                                </div>

                                                <div className="pl-10 uppercase text-sm mt-1">
                                                    <span className="font-bold mr-2">Valor:</span><span className="font-semibold">R$ {props.product.valor}</span>
                                                </div>
                                                <div className="pl-10 uppercase text-sm mt-1">
                                                    <span className="font-bold mr-2">Email:</span><span className="font-semibold">{email}</span>
                                                </div>


                                                <div className="flex mt-10 justify-center w-full">
                                                    <div className="mb-2 flex border-b-2 border-verdesixtyn ">
                                                        <img className="w-24" src={HeaderPix} alt="logo pix" />

                                                    </div>

                                                    <div className="mb-2 flex border-b-2 border-verdesixtyn ">
                                                        <img className="w-24" src={Logoplatium} alt="logo pix" />

                                                    </div>

                                                </div>

                                                <div className="w-full flex justify-center">
                                                    <div className="w-10/12">
                                                    <img src={CompraSegura} />
                                                    </div>
                                                    
                                                </div>





                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-center items-center text-white mt-3 gap-16">




                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>


        </>
    )
}